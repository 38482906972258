import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';
import { Element } from 'react-scroll';
import { scrollToViewFromBottom } from '../../utils/scrollToViewFromBottom';
import { Recommendation } from './Recommendation';
import AMSectionTitle from '../business-accelerator/AMSectionTitle';

const HomeOneAIRecommendations = () => {
	const [url, setUrl] = useState("");
	const [showResultsSection, setShowResultsSection] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [error, setError] = useState("");
	

	const changeUI = () => {
			if(!error) {
				setLoading(true);
				setShowResultsSection(true);
				scrollToViewFromBottom("AIAccelerationResults");
				// setShowResults(false);
				// const el = document.getElementById("AIAcceleration");
				// el.classList.add("ai-acceleration-results");
				// setTimeout(() => {
				// 	setShowAIAccelerationForm(true);
				// }, 3000);
			} else {
	
			}
		}
	
		const handleEnhancementFormSubmit = (e) => {
			e.preventDefault();
			setError("");
			// console.log("Form submitted.");
			// console.log("Fetching response from API.");
			// setError(error => error + "Some Error!");
			changeUI();
			// fetch(process.env.REACT_APP_AI_ENHANCEMENTS, {
			// 	method: "POST",
			// 	headers: {
			// 		"Content-Type" : "application/json"
			// 	},
			// 	body: JSON.stringify( {"url": url, "deep" : false} )
			// })
			// .then(res => res.json())
			// .then(recommendations => {
			// 	setLoading(false);
			// 	return (setData(recommendations.data))
			// })
			// .catch(error => setError(error));
			setTimeout(() => {
				Recommendation.getEnhancements().then(data => setData(data.data));
				setLoading(false);
			}, 2000);
		}

	return (
		<>
			<Element name="ai-acceleration" className="ai-acceleration-area edu-about-area about-style-1 edu-section-gap" id="AIAcceleration">
				<div className="container">
					<div className="row g-5">
						<div className="col-12">
							<SectionTitle
								classes = "text-white text-center"
								slogan = "Find AI Recommendations"
								title = "Your AI Integration Assistant"
							/>
						</div>
						<div className="col-12">
							<p className="aia-description text-white">Discover how AI can transform your business in just a few clicks! Simply enter your website URL, and our smart tool will analyze your site to recommend the top 3 AI-powered solutions tailored to your business needs—fast, easy, and impactful.</p>
							<form onSubmit={handleEnhancementFormSubmit}>
								<div className="d-flex flex-row gap-4">
									<input type="text" className="bg-white" placeholder="Enter your website url" value={url} onChange={(e) => setUrl(e.target.value)} required />
									<button type="submit" className="edu-btn btn-secondary">Submit</button>
								</div>
							</form>
							<small className="text-white text-center"><strong>Disclaimer:</strong> By submitting, you agree to allow our AI tool to analyze your publicly available website data to provide personalized recommendations.</small>
							<div className="ai-integration-instructions d-flex justify-content-center" id="AIAccelerationResults">
								<div className="ai-integration-instructions-wrapper">
									<p className="col-12 align-self-start datatalent-partners-header mt-5 mb-0 text-white"><strong>How It Works</strong>:</p>
									<ol className="col-12 align-self-start datatalent-partners-header mt-0 mb-0 text-white">
										<li>
											Enter your website URL.
										</li>
										<li>
											Get tailored AI integration suggestions.
										</li>
										<li className="mb-0">
											Start unlocking the potential of AI for your business.
										</li>
									</ol>
								</div>
							</div>
            </div>
						{showResultsSection && <div className="col-12">
              <AMSectionTitle
                classes = "text-center text-white mt-5"
                // slogan = "Introduction"
                title = "Top AI Enhancements Recommendations"
              />
							{loading && <div className="text-center">
								{/* <img src={process.env.PUBLIC_URL+"/images/loading.gif"} alt="loader" /> */}
								<img src={process.env.PUBLIC_URL+"/images/loading_pulse.gif"} alt="loader" />
								{/* <img src={process.env.PUBLIC_URL+"/images/loading3_transparent.gif"} alt="loader" /> */}
							</div>}
							{loading ? <div className="col-12 mt-60">
								<div className="row gx-4 gy-5 justify-content-center">
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											{/* <div className="enhancement-number position-absolute">1.</div> */}
											<div className="aiac-shimmer-heading"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body2"></div>
											<div className="d-flex flex-row justify-content-between">
												<div className="aiac-shimmer-cta"></div>
												<div className="aiac-shimmer-cta"></div>
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card ai-acceleration-card-2 p-4 position-relative">
											{/* <div className="enhancement-number position-absolute">2.</div> */}
											<div className="aiac-shimmer-heading"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body2"></div>
											<div className="d-flex flex-row justify-content-between">
												<div className="aiac-shimmer-cta"></div>
												<div className="aiac-shimmer-cta"></div>
											</div>
										</div>
									</div>	
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											{/* <div className="enhancement-number position-absolute">3.</div> */}
											<div className="aiac-shimmer-heading"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body2"></div>
											<div className="d-flex flex-row justify-content-between">
												<div className="aiac-shimmer-cta"></div>
												<div className="aiac-shimmer-cta"></div>
											</div>
										</div>
									</div>
								</div>
							</div> : 
							error ? <p className="ai-error">{error} Please try a different URL.</p> : <div className="col-12 mt-5">
								<div className="row gx-4 gy-5 justify-content-center">
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											<div className="enhancement-number position-absolute">1.</div>
											<h6 className="text-center mt-4">{data.enhancements[0]}</h6>
											<p>{data.explanations[0]}</p>
											<div className="d-flex flex-row justify-content-between">
												<Link to="programs/business-accelerator/#AboutBusinessAccelerator" className="edu-btn btn-medium btn-bg-alt mt-4">Learn More</Link>
												<Link to="/contact-us" className="edu-btn btn-secondary btn-medium mt-4">Schedule Call</Link>
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card ai-acceleration-card-2 p-4 position-relative">
											<div className="enhancement-number position-absolute">2.</div>
											<h6 className="text-center mt-4">{data.enhancements[1]}</h6>
											<p>{data.explanations[1]}</p>
											<div className="d-flex flex-row justify-content-between">
												<Link to="programs/business-accelerator/#AboutBusinessAccelerator" className="edu-btn btn-medium btn-bg-alt mt-4">Learn More</Link>
												<Link to="/contact-us" className="edu-btn btn-secondary btn-medium mt-4">Schedule Call</Link>
											</div>
										</div>
									</div>	
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											<div className="enhancement-number position-absolute">3.</div>
											<h6 className="text-center mt-4">{data.enhancements[2]}</h6>
											<p>{data.explanations[2]}</p>
											<div className="d-flex flex-row justify-content-between">
												<Link to="programs/business-accelerator/#AboutBusinessAccelerator" className="edu-btn btn-medium btn-bg-alt mt-4">Learn More</Link>
												<Link to="/contact-us" className="edu-btn btn-secondary btn-medium mt-4">Schedule Call</Link>
											</div>
										</div>
									</div>

									<div className="col-12">
										<p className="ai-acceleration-footer-note mb-0"><strong>Note:</strong> These results are shown based only on the webpage of the entered url.</p>
										{/* <p>Click the "deep dive" button below to get AI Enhancement recommendations based on complete website analysis.</p>
										{deepDiveEnable && <button className="edu-btn btn secondary" onClick={handleDeepDive}>Deep Dive</button>} */}
									</div>
								</div>
							</div>}
						</div>}
					</div>          
				</div>
			</Element>
		</>
	)
}

export default HomeOneAIRecommendations;