import React, { useState } from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import HomeOneContactUs from '../../components/home-one/HomeOneContactUs';
import HomeOneAIRecommendations from '../../components/home-one/HomeOneAIRecommendations';
import AIAccelerationForm from '../../components/form/ai-acceleration-form/AIAccelerationForm';
import { useForm } from '@formspree/react';

const AIEnhancements = () => {

  const [showAIAccelerationForm, setShowAIAccelerationForm] = useState(false);
  const [showResults, setShowResults] = useState(true);
  const [testedURL, setTestedURL] = useState("");

  const [state, handleSubmit] = useForm('mvggblkk');
  if(state.succeeded) {
    console.log("Successfully submitted.");
  }
      
  const handleUserInfoSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
    setShowAIAccelerationForm(false);
    setShowResults(true);
    console.log(e);
  }

  return (
    <>
      <SEO
        title="M2M Tech"
        description="Unlock the potential of your enterprise by envisioning new possibilities with our innovative AI solutions tailored for your business needs."
      />

      <HeaderTwo styles="header-transparent header-style-2" searchDisable />

      <HomeOneAIRecommendations setShowAIAccelerationForm={setShowAIAccelerationForm} showResults={showResults} setShowResults={setShowResults} setTestedURL={setTestedURL} />

      <HomeOneContactUs />

      <FooterOne />

      {/* {showAIAccelerationForm && <AIAccelerationForm setShowAIAccelerationForm={setShowAIAccelerationForm} handleUserInfoSubmit={handleUserInfoSubmit} state={state} testedURL={testedURL} />} */}
    </>
  )
}

export default AIEnhancements;