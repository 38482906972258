import React, { useState } from 'react';
import DataTalentSectionTitle from '../DataTalentSectionTitle';
import { Element } from 'react-scroll';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { DataTalentBlogData } from '../../../DataTalentBlogData';

const DataTalentCapstoneProjects = ({
  targetRefDPBS,
  innerRefDPBS,
  showCapstone,
  showWIL,
  showBlogs,
  handleCapstoneClick,
  handleWILClick,
  handleBlogsClick
}) => {
  const [toggler1, setToggler1] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);

  const video1 = ['https://www.youtube.com/embed/hGOmAKCI5VY?autoplay=1&rel=0'];
  const video2 = ['https://www.youtube.com/embed/adubOrTZcvc?autoplay=1&rel=0'];
  const video3 = ['https://www.youtube.com/embed/3dM3sSpY9sI?autoplay=1&rel=0'];

  let newDataTalentBlogData = DataTalentBlogData.sort((a, b) => b.date - a.date);
  console.log(newDataTalentBlogData);

  const items = [
    {
      id: 1,
      title: 'AI Prototype for Public Sector',
      info: 'The goal of the project is to build a prototype of an AI-based tool that can be used by public sector organizations to predict service needs based on client characteristics and results.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/public_sector_AI_v2_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/ai-prototype-for-public-sector'
    },
    {
      id: 2,
      title: 'Artificial intelligence for speech enhancement',
      info: 'This project aims to use data collection with field work to create a model to improve the performance of RMK mics, a device used to receive and deliver sound.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/AI_speech_enhancement_v2_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/artificial-intelligence-for-speech-enhancement'
    },
    {
      id: 3,
      title: 'Convert Chat Threads into a Vector Database',
      info: 'The goal of this project is to apply the latest AI & ML techniques to an existing dataset to create an AI/ML model that supports development of more content and user access with the platform\'s evolution.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/AIChat_db_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/convert-chat-threads-into-a-vector-database'
    },
    {
      id: 4,
      title: 'AI & ML for Sports Data Site',
      info: 'Development of an AI / ML model related to projecting player and team performance, or findings other insights in the data so that it can be added to the data directory in SportWise.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/AI_sports_data_thumbnail.webp',
      link: '/programs/industry-work-integrated-learning/aritificial-intelligence-and-machine-learning-for-sports-data-site'
    },{
      id: 5,
      title: 'Data Analytics Skills Assignments',
      info: 'We are launching an exciting initiative aimed at developing the next generation of data analytics talent in higher education.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/Data_Analytics_skills_thumb.webp',
      link: '/programs/work-integrated-learning/data-analytics-skills-assignments'
    },
    {
      id: 6,
      title: 'Health Sciences Data Analytics',
      info: 'Develop a pilot project to illustrate the role of multi-edge computing in Neuraura\'s long-term vision for LoOoP.',
      numberOfCourses: '12 weeks',
      modeOfCourses: 'Anywhere',
      category: 'Data Science, ML & AI',
      image: 'project-details/health_sciences_data_analytics_thumb.webp',
      link: '/programs/work-integrated-learning/health-sciences-data-analytics'
    }
  ];

  let capstoneSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // centerMode: true,
  };
  
  let WILSettings = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // centerMode: true,
  };
  
  let BlogSettings = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    // centerMode: true,
  };

  return (
    <>
      <Element
        name="CapstoneProjects"
        className="datatalent-showcase-section datatalent-section-gap"
      >
        <div className="container inner">
          <div className="row g-5 align-items-center datatalent-showcases pb-md-3 mb-3 mb-md-4">
            <div className="col-lg-12 p-0 mt-0">
              <DataTalentSectionTitle
                classes = "text-left"
                slogan = "Project & Blog Showcase"
                title = "Explore the Previous Work of Our DataTalent Participants"
              />
            </div>
            <div className="col-lg-12 mt-4 data-talent-description p-0">
              <p className="mb-0">Explore the innovative capstone projects, real-world internships, and blogs from our participants. See how they've applied their skills to real-world challenges and professional environments.</p>
            </div>
            <div className="col-lg-12 p-0" ref={targetRefDPBS}>
              <div>
                <div className="dpo-nav d-flex flex-row gap-3 me-0" ref={innerRefDPBS}>
                  <div>
                    <button className="dpo-nav-item active-program-overview" id="Capstone" onClick={handleCapstoneClick}>
                      Capstone<span className="d-none d-md-inline-block"> &nbsp;Projects</span>
                    </button>
                  </div>
                  <div>
                    <button className="dpo-nav-item" id="WILs" onClick={handleWILClick}>
                    <span className="d-block d-md-none">WILs</span><span className="d-none d-md-block">Work-Integrated-Learning (WILs)</span>
                    </button>
                  </div>
                  <div>
                    <button className="dpo-nav-item" id="Blogs" onClick={handleBlogsClick}>
                    <span className="d-block d-md-none">Blogs</span><span className="d-none d-md-block">Participant Blogs</span>
                    </button>
                  </div>
                </div>
              </div>
              {showCapstone && <div className="p-0 mt-5">
                <h4>Mastery in Action through Capstone Projects</h4>
                <div className="mt-4 data-talent-description">
                  <p className="mb-0">Demonstrate your mastery with capstone projects that highlight your newly acquired skills. Build something impactful, real-world, and portfolio-ready.</p>
                </div>
                <Slider {...capstoneSettings} className="testimonial-activation edu-slick-button slick-button-center mt-0 mb-5">
                <div className="col-12 col-md-4 mt--40 d-flex flex-column align-items-center">
                  <div className="main-image video-popup-wrapper">
                    <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/data_analysis_and_manipulation_v2.png`} alt="Video PopUp Thumb" loading="lazy" />
                    <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler1( !toggler1 ) }><span><FaPlay className="play-icon" /></span></button>
                  </div>
                  <h5>Data Analysis & Manipulation | Penelope Haro</h5>
                </div>
                <div className="col-12 col-md-4 mt--40 d-flex flex-column align-items-center">
                  <div className="main-image video-popup-wrapper">
                    <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/data_analysis_and_visualization_v2.png`} alt="Video PopUp Thumb" loading="lazy" />
                    <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler2( !toggler2 ) }><span><FaPlay className="play-icon" /></span></button>
                  </div>
                  <h5>Data Analysis & Visualization | Farzad</h5>
                </div>
                <div className="col-12 col-md-4 mt--40 d-flex flex-column align-items-center">
                  <div className="main-image video-popup-wrapper">
                    <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/predicting_concrete_strength_v3.png`} alt="Video PopUp Thumb" loading="lazy" />
                    <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler3( !toggler3 ) }><span><FaPlay className="play-icon" /></span></button>
                  </div>
                  <h5>Predicting Concrete Strength | Sola</h5>
                </div>
                </Slider>
              </div>}
              {showWIL && <div className="p-0 mt-5">
                <h4>Real-World Experience: Work-Integrated Learning Opportunities</h4>
                <div className="mt-4 data-talent-description">
                  <p className="mb-0">Work alongside top employers on paid internships and industry-based projects. Gain hands-on experience to fast-track your career in the tech field.</p>
                </div>
                <Slider {...WILSettings} className="testimonial-activation edu-slick-button slick-button-center mt-5 mb-5">
                { items.map( ( data , i ) => (
                  <div key={data.id} className="datatalent-wil-wrapper">
                    <div className="datatalent-wil col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-center">
                      <div className="service-card program-service-card service-card-1 radius-small">
                        <div className="inner row">
                          <div className="thumbnail col-12 p-0">
                            <a href={ data.link }>
                              <img src={`${process.env.PUBLIC_URL}/images/${ data.image }`} style={{height: "100%"}} alt="Category Thumb" loading="lazy" />
                            </a>
                          </div>
                          <div className="content program-info col-12">
                            <div className="program-info-content d-flex justify-content-between">
                              <span className="course-total"><i className="icon-time-line"></i> { data.numberOfCourses }</span>
                              <span className="course-total"><i className="icon-presentation"></i> { data.category }</span>
                            </div>
                            <h6 className="title mt-3"><a href={ data.link }>{ data.title }</a></h6>
                            <p className="description mt-4">{ data.info }</p>
                            <div className="d-flex justify-content-between flex-wrap mt-3">
                              {data.form ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> : <a href="/" style={{visibility : "hidden"}}>No link</a>}
                              <Link className="edu-btn btn-bg-alt mt-3" to={ data.link }>Find Out More<i className="icon-arrow-right-line-right"></i></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) ) }
                </Slider>
              </div>}
              {showBlogs && <div className="p-0 mt-5">
                <h4>Participant Perspectives through Blogs & Stories</h4>
                <div className="mt-4 data-talent-description">
                  <p className="mb-0">Explore our participants' journeys. Read about their experiences, challenges, and successes throughout the program, directly from their personal perspectives.</p>
                </div>
                <Slider {...BlogSettings} className="testimonial-activation edu-slick-button slick-button-center mt-5 mb-5">
                  { 
                    newDataTalentBlogData.toReversed().map((item) => (
                      <div
                        className="datatalent-blog"
                        key={ item.id } 
                      >
                        <div className="edu-blog blog-type-3 radius-small bg-color-white">
                          <div className="inner">
                            <div className="content">
                              <h4 className="title">
                                <a href={item.link}>{item.title}</a>
                              </h4>
                              <div className="blog-card-bottom">
                                <ul className="blog-meta">
                                  <li><i className="icon-calendar-2-line"></i>{ item.date }</li>
                                  <li><i className="icon-user-line"></i>Posted By <span className="color-primary">{item.author}</span></li>
                                </ul>
                              </div>
                            </div>
                            <div className="thumbnail">
                              <a href={item.link}>
                                <img src={`${process.env.PUBLIC_URL}/images/${item.image}`} alt="Blog Thumb" />
                              </a>
                            </div>
                            <div className="description">
                              <p style={{whiteSpaceCollapse: "preserve"}}>{ item.details }</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) )
                  }
                </Slider>
              </div>}
            </div>
            <div className="video-lightbox-wrapper m-0">
              <FsLightbox
                toggler={ toggler1 }
                sources={ [ <iframe src={video1} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
              />
            </div>
            <div className="video-lightbox-wrapper m-0">
              <FsLightbox
                toggler={ toggler2 }
                sources={ [ <iframe src={video2} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
            </div>
            <div className="video-lightbox-wrapper m-0">
              <FsLightbox
                toggler={ toggler3 }
                sources={ [ <iframe src={video3} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
              />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentCapstoneProjects;