import React from 'react'
import AMSectionTitle from './AMSectionTitle';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';


const BAPerfectFor = () => {
  return (
    <>
      <Element
        name="PerfectFor"
        className="ba-perfect-for datatalent-section-gap"
        id="PerfectFor"
      >
        <div className="container inner">
          <div className="row g-5 align-items-center">
            <div className="col-12 p-0 mt-0">
              <AMSectionTitle
                classes = "text-center"
                // slogan = "Program Overview"
                title = "Our AI Business Accelerator is perfect for"
              />
            </div>
            <div className="ba-perfect-for-card-section col-12 row g-4">
              <div className="col-12 col-lg-6 order-1 order-lg-1">
                <div className="ba-perfect-for-card ba-perfect-for-card-cream h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Startups</h5>
                    <p>Discover transformative AI use cases and develop your first AI-powered product with expert guidance and resources.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/startups.png`} alt="illustration" />
                </div>
              </div>
              <div className="col-12 col-lg-6 order-4 order-lg-2">
                <div className="ba-perfect-for-card ba-perfect-for-card-white h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Established Businesses</h5>
                    <p>Leverage AI to drive innovation and efficiency in industries such as FinTech, Healthcare, Retail, Energy, and Sustainability.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/established_businesses.png`} alt="illustration" />
                </div>
              </div>
              <div className="col-12 col-lg-6 order-2 order-lg-3">
                <div className="ba-perfect-for-card ba-perfect-for-card-white h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Small and Medium Enterprises <span className="d-none d-md-inline-block">(SMEs)</span></h5>
                    <p>Enhance your AI capabilities, optimize processes, and scale your operations with tailored solutions.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/smes.png`} alt="illustration" />
                </div>
              </div>
              <div className="col-12 col-lg-6 order-3 order-lg-4">
                <div className="ba-perfect-for-card ba-perfect-for-card-cream h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Non-Tech Companies</h5>
                    <p>Unlock the potential of AI to streamline operations, improve decision-making, and boost overall efficiency.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/non-tech.png`} alt="illustration" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAPerfectFor;