import React from 'react'
import AMSectionTitle from './AMSectionTitle';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';

const BAApplicationProcess = () => {

  return (
    <>
      <Element
        name="ApplicationProcess"
        className="ba-application-process datatalent-section-gap"
        id="ApplicationProcess"
      >
        <div className="container inner">
          <div className="row g-5 align-items-center">
            <div className="col-12 p-0 mt-0">
              <AMSectionTitle
                classes = "text-center"
                // slogan = "Program Overview"
                title = "Application process"
              />
            </div>
            <div className="col-12">
              <div className="ba-application-process-key-points d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex flex-row w-100">
                  <div className="col-3">
                    <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/application_3.svg`} alt="illustration" />
                  </div>
                  <div className="col-9 m-auto">
                    <h5 className="mb-3">Step 1: Book a Call</h5>
                    <p className="mb-0">Schedule a consultation to discuss your project, business goals, and how M2M's Business Accelerator can support your AI journey.</p>
                  </div>
                </div>
                <div className="d-flex flex-row mt-4 w-100">
                  <div className="col-3">
                    <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/application_2.svg`} alt="illustration" />
                  </div>
                  <div className="col-9 m-auto">
                    <h5 className="mb-3">Step 2: Fill Out the Application</h5>
                    <p className="mb-0">Submit details about your business and AI initiative through our streamlined application process.</p>
                  </div>
                </div>
                <div className="d-flex flex-row mt-4 w-100">
                  <div className="col-3">
                    <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/application_1.svg`} alt="illustration" />
                  </div>
                  <div className="col-9 m-auto">
                    <h5 className="mb-3">Step 3: Approval Process</h5>
                    <p className="mb-0">Your project will be reviewed and approved within 6-8 weeks, ensuring a thorough evaluation of its potential and alignment with program goals.</p>
                  </div>
                </div>
              </div>
                <p className="text-center mt-5 mb-0">Start your application today and take the first step toward transforming your business with AI!</p>
              <div className="ba-application-process-cta">
                <h5 className="mb-3">Need to Start Sooner?</h5>
                <p>Ready to fast-track your AI journey? If you'd like to bypass the wait time and start immediately, book a call or reach out to us at <a href="mailto:contact@m2mtechconnect.com">contact@m2mtechconnect.com</a> for a <strong>custom quote</strong> and <strong>tailored solutions</strong> to get started faster.</p>
                <Link to="/contact-us" className="edu-btn btn-primary">Book a Call</Link>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="ba-application-process-support row g-5 align-items-center">
                <div className="col-12 col-lg-3">
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/dedicated_team.svg`} alt="illustration" />
                </div>
                <div className="col-12 col-lg-9">
                  <h4>Dedicated Support Team</h4>
                  <h5>At M2M, we're with you every step of the way. Our dedicated support team is here to ensure your success, offering personalized guidance, timely assistance, and expert solutions to help you overcome challenges and achieve your AI goals seamlessly.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAApplicationProcess;