import React from 'react';
import { Element } from 'react-scroll';

const BASupport = () => {

  return (
    <>
      <Element className="ba-support edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 mt-0 p-0">
              <div className="ba-support-wrapper mx-md-5 d-flex flex-row flex-wrap">
                <div className="col-12 col-md-8 p-5">
                  <h4>What is the Business Accelerator?</h4>
                  <h5 className="d-none d-md-block">M2M's Business Accelerator empowers businesses to seamlessly integrate AI into their products and operations. With our tailored approach, we provide the expertise, tools, and hands-on support needed to bring your AI vision to reality.</h5>
                  <p className="d-block d-md-none">M2M's Business Accelerator empowers businesses to seamlessly integrate AI into their products and operations. With our tailored approach, we provide the expertise, tools, and hands-on support needed to bring your AI vision to reality.</p>
                  <div className="ba-support-bullets row gx-4 flex-wrap">
                    <div className="col-12 col-lg-6 d-flex flex-row">
                      <div className="col-1">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/green_check.svg`} alt="illustration" />
                      </div>
                      <div className="col-11 ms-3">
                        <h5 className="mb-2">Technical Expertise</h5>
                        <p className="mb-0">Gain access to a team of AI professionals with deep knowledge of industry-specific applications, ready to design and implement solutions tailored to your needs.</p>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex flex-row">
                      <div className="col-1">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/green_check.svg`} alt="illustration" />
                      </div>
                      <div className="col-11 ms-3">
                        <h5 className="mb-2">Collaborative Support</h5>
                        <p className="mb-0">Work alongside a dedicated team that partners with you through every stage of the AI prototype phase, ensuring smooth execution and measurable results.</p>
                      </div>
                    </div>
                  </div>
                  <p className="text-center mt-5 mb-0">Turn your innovative ideas into impactful AI solutions with M2M's Business Accelerator.</p>
                </div>
                <div className="col-12 col-md-4 p-4 d-flex align-items-center">
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/highlight_section.png`} alt="illustration" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BASupport;