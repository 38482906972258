import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import BAServiceTabContent from './BAServiceTabContent.json';
import BAVerticalTab from './ba-services/BAVerticalTab';

const BAAbout = () => {

  const keyPoints = [
    {
      image: "flexible.svg",
      title: "Flexible, Self-Paced Learning",
      description: "Study on your terms with 24/7 access to courses and materials, allowing you to balance learning with your busy schedule."
    },
    {
      image: "hands_on.svg",
      title: "Hands-On Experience",
      description: "Work on industry-relevant projects that mirror real-world challenges. You'll develop practical skills, build a portfolio, and gain the confidence to apply your knowledge in professional settings."
    },
    {
      image: "expert.svg",
      title: "Expert Guidance",
      description: "Benefit from personalized mentorship with one-on-one sessions tailored to your goals. Our expert mentors provide career advice, feedback on projects, and help you navigate your journey with confidence."
    }
  ]

  const coreValues = [
    {
      image: "engage.svg",
      title: "Engage",
      description: "Connect with industry experts through hands-on projects that expand your network and boost your career."
    },
    {
      image: "educate.svg",
      title: "Educate",
      description: "Master in-demand skills like AI & ML through our interactive courses, tackling real-world challenges that set you apart."
    },
    {
      image: "empower.svg",
      title: "Empower",
      description: "Lead projects, collaborate with peers, and work alongside professionals with the autonomy we provide."
    }
  ]

  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap" name="about-business-accelerator" id="AboutBusinessAccelerator">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-center"
                // slogan = "Introduction"
                title = "Empowering Your AI Journey: Our Accelerator Services"
              />
              <p className="ba-description mt-4 text-center">Our AI-Focused Business Accelerator is designed to deliver tangible value to startups, small businesses, and established companies looking to integrate AI into their operations or products. Here's how we guide you through the key phases of the AI journey:</p>
            </div>
            <div className="col-12 mt-5 p-0">
              <BAVerticalTab BAServiceTabContent={BAServiceTabContent} />
              <p className="text-center mt-5">Partner with us to accelerate your journey through every stage of AI adoption and integration, ensuring sustainable growth and impactful innovation.</p>
            </div>
            <div className="col-12 p-0 d-flex flex-row flex-wrap">
              <div className="col-12 col-lg-6 pe-md-3">
                <div className="ba-about-left-card d-flex flex-row align-items-center">
                  <div className="col-4">
                    <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/innovate_faster.png`} alt="illustration" />
                  </div>
                  <div className="col-8 d-flex flex-row p-4">
                    <div className="col-1">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/certificate.svg`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-2">Innovate Faster</h5>
                      <p className="mb-0">Bring your AI vision to life quickly with rapid prototyping, pilot testing, and hands-on expertise tailored to your business needs, helping you stay ahead of the competition.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 ps-md-3 mt-md-0 mt-4">
                <div className="ba-about-right-card d-flex flex-row align-items-center">
                  <div className="col-8 d-flex flex-row p-4">
                    <div className="col-1">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/file.svg`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-2">Reduce Costs & Risks</h5>
                      <p className="mb-0">Leverage exclusive funding opportunities, expert mentorship, and industry-leading resources to minimize the costs and risks of AI adoption, ensuring a smooth and confident transition into AI-driven operations.</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/costs.png`} alt="illustration" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 container order-3 p-0">
              <div className="row am-core-values-container py-5 px-4">
                <h5 className="col-12 text-center">Achieve More with the Support of Our Core Values</h5>
                {coreValues.map((coreValue, idx) => (<div className="col-lg-4" key={idx}>
                  <div className="core-value-container d-flex flex-row align-items-center mt-4">
                    <div className="core-value-img col-4 col-lg-5">
                      <img src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/${coreValue.image}`} alt="illustration" />
                    </div>
                    <div className="core-value-content col-8 col-lg-7">
                      <h5 className="mb-0 ms-3">{coreValue.title}</h5>
                      <p className="mb-0 ms-3">{coreValue.description}</p>
                    </div>
                  </div>
                </div>))}
              </div>
            </div> */}
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAAbout;