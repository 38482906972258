import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import Slider from 'react-slick';

const BABenefits = () => {
  
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
  };
  const settingsMobile = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
  };
  
  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap ba-benefits" name="benefits-business-accelerator" id="BenefitsBusinessAccelerator">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-center"
                // slogan = "Introduction"
                title = "Benefit with our Expertise Across Key Industries"
              />
              <p className="ba-description mt-4">
                At M2M, we recognize that every industry faces unique challenges and opportunities when it comes to AI adoption. Our Business Accelerator is designed to provide tailored solutions that address these specific needs, enabling businesses to harness AI's full potential in impactful ways.</p>
              <p className="ba-description mt-5">
                With expertise across diverse sectors, we deliver industry-aligned strategies to tackle pressing challenges and drive innovation where it matters most. Let us help your business unlock new possibilities, whether in Advanced Manufacturing, CleanTech, HealthTech, Digital Technology, or beyond.</p>
              <p className="ba-description mt-5">
                Explore how AI can transform your industry and start your journey with M2M today.
              </p>
            </div>
            <div className="col-12 mt--40">
              <Slider {...settings} className="testimonial-activation edu-slick-button slick-button-center d-none d-md-block">
                <div>
                  <div className="ba-benefits-card ba-benefits-card0">
                    <p className="ba-benefits-card-content">FinTech</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card1">
                    <p className="ba-benefits-card-content">Clean<br></br>Technology</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card2">
                    <p className="ba-benefits-card-content">IoT, 5G &<br></br>Cybersecurity</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card3">
                    <p className="ba-benefits-card-content">Advanced<br></br>Manufacturing</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card4">
                    <p className="ba-benefits-card-content">Business<br></br>Intelligence</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card5">
                    <p className="ba-benefits-card-content">Health<br></br>Technology</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card6">
                    <p className="ba-benefits-card-content">AgTech</p>
                  </div>
                </div>
              </Slider>
              <Slider {...settingsMobile} className="testimonial-activation edu-slick-button slick-button-center d-block d-md-none">
                <div>
                  <div className="ba-benefits-card ba-benefits-card1">
                    <p className="ba-benefits-card-content">Clean<br></br>Technology</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card2">
                    <p className="ba-benefits-card-content">IoT, 5G &<br></br>Cybersecurity</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card3">
                    <p className="ba-benefits-card-content">Advanced<br></br>Manufacturing</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card4">
                    <p className="ba-benefits-card-content">Business<br></br>Intelligence</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card5">
                    <p className="ba-benefits-card-content">Health<br></br>Technology</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BABenefits;