import React from 'react';

const BACPONavbar = ({
  isSticky,
  handleBusinessCoachingAdvisoryClick,
  handleFundingResourcesClick,
  activeBACPOTab
}) => {

  return (
    <>
      {isSticky && <div className={`dpo-nav d-flex flex-row gap-3${isSticky ? " dpo-nav-sticky" : ""}`} id="BACPOMobileNavbar">
        <div>
          <button className={`dpo-nav-item${activeBACPOTab === "BusinessCoachingAdvisory" ? " active-program-overview" : ""}`} id="BusinessCoachingAdvisoryMobileNavbar" onClick={handleBusinessCoachingAdvisoryClick}>
            Business Coaching
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeBACPOTab === "FundingResources" ? " active-program-overview" : ""}`} id="FundingResourcesMobileNavbar" onClick={handleFundingResourcesClick}>
            Funding & Resources
          </button>
        </div>
      </div>}
    </>
  )
}

export default BACPONavbar;