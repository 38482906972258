import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import { scrollToViewFromTop } from '../../utils/scrollToViewFromTop';

const BAComprehensive = ({
  targetRefBAC,
  innerRefBAC,
  showBusinessCoachingAdvisory,
  showFundingResources,
  handleBusinessCoachingAdvisoryClick,
  handleFundingResourcesClick
}) => {

  return (
    <>
      <Element
        name="ProgramOverview"
        className="am-program-overiew datatalent-section-gap"
        id="ProgramOverview"
      >
        <div className="container inner">
          <div className="row g-5 align-items-center datatalent-showcases">
            <div className="col-lg-12 p-0 mt-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Program Overview"
                title = "Comprehensive Business Support: Coaching, Advisory, and Resources"
              />
              <p className="ba-comprehensive-description">M2M's AI Ready program offers more than just technical expertise. We provide access to valuable funding opportunities, cutting-edge resources, and hands-on guidance, empowering businesses to explore AI with minimized risks and maximized potential.</p>
            </div>
            <div className="col-lg-12 mt-0 p-0" ref={targetRefBAC}>
              <div>
                <div className="dpo-nav d-flex flex-row gap-3 me-0" ref={innerRefBAC}>
                  <div>
                    <button className="dpo-nav-item active-program-overview" id="BusinessCoachingAdvisory" onClick={handleBusinessCoachingAdvisoryClick}>
                      Business Coaching <span className="d-none d-md-inline-block">& Advisory</span>
                    </button>
                  </div>
                  <div>
                    <button className="dpo-nav-item" id="FundingResources" onClick={handleFundingResourcesClick}>
                      Funding & Resources
                    </button>
                  </div>
                </div>
              </div>
              {showBusinessCoachingAdvisory && <>
                <div className="p-0 row g-5">
                  <div className="col-12 col-lg-7">
                    <h4>Business Coaching & Advisory</h4>
                    <p>
                      Receive expert guidance to build a sustainable, scalable, and compliant AI-driven business model.
                    </p>
                    <span style={{color: "var(--color-primary)"}}>FEATURES</span>
                    <div className="d-flex flex-row mt-4">
                      <div className="col-1 mt-4">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/business_model.png`} alt="illustration" />
                      </div>
                      <div className="col-11">
                        <h5 className="mb-3">Business Model Validation</h5>
                        <p>Ensure your AI strategy aligns with market demands and is both viable and scalable.</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="col-1 mt-4">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/funding_strategy.png`} alt="illustration" />
                      </div>
                      <div className="col-11">
                        <h5 className="mb-3">Funding Strategy</h5>
                        <p>Unlock access to grants, venture capital, and funding opportunities tailored to tech startups and SMEs.</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="col-1 mt-4">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/regulatory_compliance.png`} alt="illustration" />
                      </div>
                      <div className="col-11">
                        <h5 className="mb-3">Regulatory Compliance</h5>
                        <p>Stay ahead of data privacy laws and AI ethics standards with our expert advisory services.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/business_resources.png`} alt="illustration" />
                  </div>
                </div>
              </>}
              {showFundingResources && <>
                <div className="p-0 row g-5">
                  <div className="col-12 col-lg-7">
                    <h4>Funding & Resources</h4>
                    <p>
                      Reduce the costs and risks of AI projects with M2M's financial support, mentorship, and infrastructure guidance.
                    </p>
                    <span style={{color: "var(--color-primary)"}}>FEATURES</span>
                    <div className="d-flex flex-row mt-4">
                      <div className="col-1 mt-4">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/business_model.png`} alt="illustration" />
                      </div>
                      <div className="col-11">
                        <h5 className="mb-3">Initial Funded Support</h5>
                        <p>Collaborate with M2M's skilled talent pool and professional team for 4-5 months at no cost, aligning your AI strategy with industry standards.</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="col-1 mt-4">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/funding_strategy.png`} alt="illustration" />
                      </div>
                      <div className="col-11">
                        <h5 className="mb-3">Success-Based Funding</h5>
                        <p>Eligible projects can access up to $50,000 CAD in additional funding to commercialize AI solutions.</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="col-1 mt-4">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/regulatory_compliance.png`} alt="illustration" />
                      </div>
                      <div className="col-11">
                        <h5 className="mb-3">Funding Guidance</h5>
                        <p>Expert assistance in navigating and securing the right funding opportunities for your project.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/business_resources.png`} alt="illustration" />
                  </div>
                </div>
              </>}
              <p className="text-center mt-5 mb-0">M2M's comprehensive support ensures that your AI journey is strategic, sustainable, and successful.</p>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAComprehensive;