import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import ContactUs from './pages/innerpages/ContactUs';
import HomeOne from './pages/homepages/HomeOne';
import ResearchOne from './pages/researchpages/ResearchOne';
// import DataAnalysisAndDataVisualization from './pages/innerpages/DataAnalysisAndDataVisualization';
import WorkIntegratedLearning from './pages/workintegratedlearningpages/WorkIntegratedLearning';
import CleanTechnology from './pages/researchpages/CleanTechnology';
import AdvancedManufacturing from './pages/researchpages/AdvancedManufacturing';
import Iot5gAndCyberSecurity from './pages/researchpages/Iot5gAndCyberSecurity';
import BusinessIntelligence from './pages/researchpages/BusinessIntelligence';
import FinTech from './pages/researchpages/FinTech';
import HealthTech from './pages/researchpages/HealthTech';
import AgTech from './pages/researchpages/AgTech';
import Error from './pages/innerpages/Error';
import PredictiveMaintenanceSolution from './pages/innerpages/PredictiveMaintenanceSolution';
import CleanEnergyConsumptionForeCastingProject from './pages/innerpages/CleanEnergyConsumptionForecastingProject';
import CleanEnergyManagementInSmartGrids from './pages/innerpages/CleanEnergyManagementInSmartGrids';
import CustomerSegmentation from './pages/innerpages/CustomerSegmentation';
import MedicalDiagnosisProject from './pages/innerpages/MedicalDiagnosisProject';
import HousingMarketPricePrediction from './pages/innerpages/HousingMarketPricePrediction';
import CropYieldPredictionProject from './pages/innerpages/CropYieldPredictionProject';
import SupplyChainOptimization from './pages/innerpages/SupplyChainOptimization';
import BusinessIntelligenceDataVisualizationProject from './pages/innerpages/BusinessIntelligenceDataVisualizationProject';
import HealthcareMonitoringAndManagementProject from './pages/innerpages/HealthcareMonitoringAndManagementProject';
import IntrusionDetectionProject from './pages/innerpages/IntrusionDetectionProject';
import ArtificialIntelligenceAndMachineLearningApplication from './pages/innerpages/ArtificialIntelligenceAndMachineLearningApplication';
// import CareerServices from './pages/careerservicespages/CareerServices';
import Courses from './pages/coursespages/Courses';
import StudentsSpotlight from './pages/studentspotlightpages/StudentsSpotlight';
import DataAnalytics from './pages/coursespages/DataAnalytics';
import DataAnalyticsPotentialJobsDetails from './components/courses/data-analytics/potential-jobs/DataAnalyticsPotentialJobsDetails';
import CoursesCoding from './pages/coursespages/CoursesCoding';
import CoursesCodingPotentialJobsDetails from './components/courses/courses-coding/potential-jobs/CoursesCodingPotentialJobsDetails';
import CoursesDesign from './pages/coursespages/CoursesDesign';
import CoursesDesignPotentialJobsDetails from './components/courses/courses-design/potential-jobs/CoursesDesignPotentialJobsDetails';
import CoursesMachineLearning from './pages/coursespages/CoursesMachineLearning';
import CoursesMachineLearningPotentialJobsDetails from './components/courses/courses-machine-learning/potential-jobs/CoursesMachineLearningPotentialJobsDetails';
import CoursesDeepLearning from './pages/coursespages/CoursesDeepLearning';
import CoursesDeepLearningPotentialJobsDetails from './components/courses/courses-deep-learning/potential-jobs/CoursesDeepLearningPotentialJobsDetails';
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import DataTalent from './pages/datatalentpages/DataTalent';
import Skillsboost from './pages/skillsboostpages/Skillsboost';
import Aiminds from './pages/aimindspages/Aiminds';
import Jobs from './pages/jobspages/Jobs';
import AIForm from './pages/aipages/AIForm';
import Events from './pages/eventspages/Events';
import IndustryAlignedUpskilling from './pages/industryalignedupskillingpages/IndustryAlignedUpskilling';
import DiscoverAI from './pages/discoveraipages/DiscoverAI';
import AppliedAISolutions from './pages/oursolutions/appliedaisolutions/AppliedAISolutions';
import PostAI from './pages/oursolutions/appliedaisolutions/subpages/PostAI';
import FourSight from './pages/oursolutions/appliedaisolutions/subpages/FourSight';
import RealEstateOutlier from './pages/oursolutions/appliedaisolutions/subpages/RealEstateOutlier';
import CourseVibe from './pages/oursolutions/appliedaisolutions/subpages/CourseVibe';
import SDGNavigator from './pages/oursolutions/appliedaisolutions/subpages/SDGNavigator';
import TestGenius from './pages/oursolutions/appliedaisolutions/subpages/TestGenius';
import HealthGuidePlus from './pages/oursolutions/appliedaisolutions/subpages/HealthGuidePlus';
import ReturnToWorkPrediction from './pages/returntoworkpredictionpages/ReturnToWorkPrediction';
import ReturnToWorkPredictionTest from './pages/returntoworkpredictionpages/ReturnToWorkPredictionTest';
import Jobseeker from './pages/jobseekerpages/Jobseeker';
import JobseekerTest from './pages/jobseekerpages/JobseekerTest';
import HealthCareInsights from './pages/healthcareinsights/HealthCareInsights';
import AIPrototypeForPublicSector from './pages/workintegratedlearningpages/innerpages/AIPrototypeForPublicSector';
import AIForSpeechEnhancement from './pages/workintegratedlearningpages/innerpages/AIForSpeechEnhancement';
import ConvertChatThreadsIntoVectorDB from './pages/workintegratedlearningpages/innerpages/ConvertChatThreadsIntoVectorDB';
import AIANDMLForSportsDataSite from './pages/workintegratedlearningpages/innerpages/AIANDMLForSportsDataSite';
import AboutUs from './pages/aboutuspages/AboutUs';
import RegulatoryDashboardPage from './pages/regulatorydashboardpages/RegulatoryDashboardPage';

import { ToastContainer as ToastifyContainer } from 'react-toastify';

// Import PDF Here
import DataAnalyticsCurriculum from './assets/courses-curriculums/Data-Analytics-Curriculum.pdf';
import MachineLearningCurriculum from './assets/courses-curriculums/Machine-Learning-Curriculum.pdf';
import DeepLearningCurriculum from './assets/courses-curriculums/Deep-Learning-Curriculum.pdf';
import CodingCurriculum from './assets/courses-curriculums/Coding-Curriculum.pdf';
import DesignCurriculum from './assets/courses-curriculums/Design-Curriculum.pdf';
import SpotlightPackage from './assets/spotlight/STEM_STUDENT_SPOTLIGHT_PROGRAM_PACKAGE.pdf';
import DataTalentCurriculum from './assets/datatalent/Data-Science-ML-AI-Curriculum.pdf';

// Import Css Here 
import './assets/scss/style.scss';
import DataAnalyticsSkillsAssignments from './pages/workintegratedlearningpages/innerpages/DataAnalyticsSkillsAssignments';
import HealthSciencesDataAnalytics from './pages/workintegratedlearningpages/innerpages/HealthSciencesDataAnalytics';
import HealthcareChatbot from './pages/healthcarechatbot/HealthcareChatbot';
import RegulatoryDashboardPage2 from './pages/regulatorydashboardpages/RegulatoryDashboardPage2';
import DataTalentJobseekers from './pages/datatalentpages/jobseekerspages/DataTalentJobseekers';
import AnnualMembership from './pages/annualmembershippages/AnnualMembership';
import AIEnhancements from './pages/aienhancements/AIEnhancements';
import BusinessAccelerator from './pages/businessacceleratorpages/BusinessAccelerator';

function App() {

  const [showUserInterestForm, setShowUserInterestForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showDownloadCurriculumForm, setShowDownloadCurriculumForm] = useState(false);
  const [employerRegisterForm, setShowEmployerRegisterForm] = useState(false);
  const [showSkillsboostSignUpForm, setShowSkillsboostSignUpForm] = useState(false);
  
  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route exact path='/' element={<HomeOne showUserInterestForm={showUserInterestForm} setShowUserInterestForm={setShowUserInterestForm} showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} showSkillsboostSignUpForm={showSkillsboostSignUpForm} setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />}/>
            <Route exact path='/aiacceleration' element={<AIEnhancements />}/>
            <Route exact path='/contact-us' element={<ContactUs/>}/>
            {/* <Route exact path='/sitemap-generator' element={<Sitemap />}/> */}
            <Route exact path='/solutions/applied-ai-solutions' element={<AppliedAISolutions />}/>
            <Route exact path='/solutions/applied-ai-solutions/foursight' element={<FourSight />}/>
            <Route exact path='/solutions/applied-ai-solutions/postai' element={<PostAI />}/>
            <Route exact path='/solutions/applied-ai-solutions/real-estate-outlier' element={<RealEstateOutlier />}/>
            <Route exact path='/solutions/applied-ai-solutions/coursevibe' element={<CourseVibe />}/>
            <Route exact path='/solutions/applied-ai-solutions/sdg-navigator' element={<SDGNavigator />}/>
            <Route exact path='/solutions/applied-ai-solutions/testgenius' element={<TestGenius />}/>
            <Route exact path='/solutions/applied-ai-solutions/healthguide-plus' element={<HealthGuidePlus />}/>
            <Route exact path='/solutions/industry-aligned-upskilling' element={<IndustryAlignedUpskilling showUserInterestForm={showUserInterestForm} setShowUserInterestForm={setShowUserInterestForm} showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} showSkillsboostSignUpForm={showSkillsboostSignUpForm} setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />}/>
            <Route exact path='/courses' element={<Courses />}/>
            <Route exact path='/courses/data-analytics' element={<DataAnalytics showDownloadCurriculumForm={showDownloadCurriculumForm} setShowDownloadCurriculumForm={setShowDownloadCurriculumForm} Download={DataAnalyticsCurriculum} downloadFileName={'Data-Analytics-Curriculum'} formName={'Download Data Analytics Curriculum form on courses/data-analytics page'} formSubmitFrom={'https://m2mtechconnect.com/courses/data-analytics/'} />}/>
            <Route exact path='/courses/data-analytics/potential-jobs/:id' element={<DataAnalyticsPotentialJobsDetails />}/>
            <Route exact path='/courses/coding' element={<CoursesCoding showDownloadCurriculumForm={showDownloadCurriculumForm} setShowDownloadCurriculumForm={setShowDownloadCurriculumForm} Download={CodingCurriculum} downloadFileName={'Coding-Curriculum'} formName={'Download Coding Curriculum form on courses/coding page'} formSubmitFrom={'https://m2mtechconnect.com/courses/coding/'} />}/>
            <Route exact path='/courses/coding/potential-jobs/:id' element={<CoursesCodingPotentialJobsDetails />}/>
            <Route exact path='/courses/design' element={<CoursesDesign showDownloadCurriculumForm={showDownloadCurriculumForm} setShowDownloadCurriculumForm={setShowDownloadCurriculumForm} Download={DesignCurriculum} downloadFileName={'Design-Curriculum'} formName={'Download Design Curriculum form on courses/design page'} formSubmitFrom={'https://m2mtechconnect.com/courses/design/'} />}/>
            <Route exact path='/courses/design/potential-jobs/:id' element={<CoursesDesignPotentialJobsDetails />}/>
            <Route exact path='/courses/machine-learning' element={<CoursesMachineLearning showDownloadCurriculumForm={showDownloadCurriculumForm} setShowDownloadCurriculumForm={setShowDownloadCurriculumForm} Download={MachineLearningCurriculum} downloadFileName={'Machine-Learning-Curriculum'} formName={'Download Machine Learning Curriculum form on courses/machine-learning page'} formSubmitFrom={'https://m2mtechconnect.com/courses/machine-learning/'} />}/>
            <Route exact path='/courses/machine-learning/potential-jobs/:id' element={<CoursesMachineLearningPotentialJobsDetails />}/>
            <Route exact path='/courses/deep-learning' element={<CoursesDeepLearning showDownloadCurriculumForm={showDownloadCurriculumForm} setShowDownloadCurriculumForm={setShowDownloadCurriculumForm} Download={DeepLearningCurriculum} downloadFileName={'Deep-Learning-Curriculum'} formName={'Download Deep Learning Curriculum form on courses/deep-learning page'} formSubmitFrom={'https://m2mtechconnect.com/courses/deep-learning/'} />}/>
            <Route exact path='/courses/deep-learning/potential-jobs/:id' element={<CoursesDeepLearningPotentialJobsDetails />}/>
            <Route exact path='/datatalent' element={<Navigate to='/programs/datatalent/jobseekers' />} />
            <Route exact path='/programs' element={<Navigate to='/programs/datatalent/jobseekers' />} />
            <Route exact path='/programs/datatalent' element={<Navigate to='/programs/datatalent/jobseekers' />}/>
            <Route exact path='/programs/datatalent/jobseekers' element={<DataTalentJobseekers showUserInterestForm={showUserInterestForm} setShowUserInterestForm={setShowUserInterestForm} employerRegisterForm={employerRegisterForm} setShowEmployerRegisterForm={setShowEmployerRegisterForm} showDownloadCurriculumForm={showDownloadCurriculumForm} setShowDownloadCurriculumForm={setShowDownloadCurriculumForm} Download={DataTalentCurriculum} downloadFileName={'Data-Science-ML-AI-Curriculum'} formName={'Download Datatalent Curriculum form on programs/datatalent page'} formSubmitFrom={'https://m2mtechconnect.com/programs/datatalent/'} />}/>
            <Route exact path='/skillsboost' element={<Navigate to='/programs/skillsboost' />} />
            <Route exact path='/programs/skillsboost' element={<Skillsboost showSkillsboostSignUpForm={showSkillsboostSignUpForm} setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />}/>
            <Route exact path='/aiminds' element={<Navigate to='/programs/aiminds' />}/>
            <Route exact path='/programs/aiminds' element={<Aiminds />}/>
            <Route exact path='/programs/membership' element={<AnnualMembership />}/>
            <Route exact path='/programs/business-accelerator' element={<BusinessAccelerator />}/>
            <Route exact path='/careers/work-integrated-learning' element={<Navigate to='/programs/work-integrated-learning' />}/>
            <Route exact path='/work-integrated-learning' element={<Navigate to='/programs/work-integrated-learning' />}/>
            <Route exact path='/programs/work-integrated-learning' element={<WorkIntegratedLearning showUserInterestForm={showUserInterestForm} setShowUserInterestForm={setShowUserInterestForm} />}/>
            <Route exact path='/careers' element={<Jobs />} />
            <Route exact path='/research/clean-technology' element={<CleanTechnology showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            <Route exact path='/research/advanced-manufacturing' element={<AdvancedManufacturing showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            <Route exact path='/research/iot-5g-and-cybersecurity' element={<Iot5gAndCyberSecurity showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            <Route exact path='/research/business-intelligence' element={<BusinessIntelligence showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            <Route exact path='/research/fintech' element={<FinTech showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            <Route exact path='/research/healthtech' element={<HealthTech showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            <Route exact path='/research/agtech' element={<AgTech showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/data-analysis-and-data-visualization' element={<DataAnalysisAndDataVisualization showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/> */}
            {/* <Route exact path='/work-integrated-learning/artificial-intelligence-and-machine-learning-application' element={<Navigate to="/careers/work-integrated-learning/artificial-intelligence-and-machine-learning-application" />}/> */}
            <Route exact path='/programs/work-integrated-learning/ai-prototype-for-public-sector' element={<AIPrototypeForPublicSector showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} company="COMPANY" />}/>
            <Route exact path='/programs/work-integrated-learning/artificial-intelligence-for-speech-enhancement' element={<AIForSpeechEnhancement showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} company="COMPANY" />}/>
            <Route exact path='/programs/work-integrated-learning/convert-chat-threads-into-a-vector-database' element={<ConvertChatThreadsIntoVectorDB showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} company="COMPANY" />}/>
            <Route exact path='/programs/work-integrated-learning/aritificial-intelligence-and-machine-learning-for-sports-data-site' element={<AIANDMLForSportsDataSite showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} company="COMPANY" />}/>
            <Route exact path='/programs/work-integrated-learning/data-analytics-skills-assignments' element={<DataAnalyticsSkillsAssignments showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} company="COMPANY" />}/>
            <Route exact path='/programs/work-integrated-learning/health-sciences-data-analytics' element={<HealthSciencesDataAnalytics showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} company="COMPANY" />}/>
            <Route exact path='/programs/work-integrated-learning/artificial-intelligence-and-machine-learning-application' element={<ArtificialIntelligenceAndMachineLearningApplication showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/predictive-maintenance-solution' element={<Navigate to="/careers/work-integrated-learning/predictive-maintenance-solution" />}/> */}
            <Route exact path='/programs/work-integrated-learning/predictive-maintenance-solution' element={<PredictiveMaintenanceSolution showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/clean-energy-consumption-forecasting-project' element={<Navigate to="/careers/work-integrated-learning/clean-energy-consumption-forecasting-project" />}/> */}
            <Route exact path='/programs/work-integrated-learning/clean-energy-consumption-forecasting-project' element={<CleanEnergyConsumptionForeCastingProject showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/clean-energy-management-in-smart-grids' element={<Navigate to="/careers/work-integrated-learning/clean-energy-management-in-smart-grids" />}/> */}
            <Route exact path='/programs/work-integrated-learning/clean-energy-management-in-smart-grids' element={<CleanEnergyManagementInSmartGrids showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/customer-segmentation' element={<Navigate to="/careers/work-integrated-learning/customer-segmentation" />}/> */}
            <Route exact path='/programs/work-integrated-learning/customer-segmentation' element={<CustomerSegmentation showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/medical-diagnosis-project' element={<Navigate to="/careers/work-integrated-learning/medical-diagnosis-project" />}/> */}
            <Route exact path='/programs/work-integrated-learning/medical-diagnosis-project' element={<MedicalDiagnosisProject showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/housing-market-price-prediction' element={<Navigate to="/careers/work-integrated-learning/housing-market-price-prediction" />}/> */}
            <Route exact path='/programs/work-integrated-learning/housing-market-price-prediction' element={<HousingMarketPricePrediction showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/crop-yield-prediction-project' element={<Navigate to="/careers/work-integrated-learning/crop-yield-prediction-project" />}/> */}
            <Route exact path='/programs/work-integrated-learning/crop-yield-prediction-project' element={<CropYieldPredictionProject showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/supply-chain-optimization' element={<Navigate to="/careers/work-integrated-learning/supply-chain-optimization" />}/> */}
            <Route exact path='/programs/work-integrated-learning/supply-chain-optimization' element={<SupplyChainOptimization showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/business-intelligence-data-visualization-project' element={<Navigate to="/careers/work-integrated-learning/business-intelligence-data-visualization-project" />}/> */}
            <Route exact path='/programs/work-integrated-learning/business-intelligence-data-visualization-project' element={<BusinessIntelligenceDataVisualizationProject showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/healthcare-monitoring-and-management-project' element={<Navigate to="/careers/work-integrated-learning/healthcare-monitoring-and-management-project" />}/> */}
            <Route exact path='/programs/work-integrated-learning/healthcare-monitoring-and-management-project' element={<HealthcareMonitoringAndManagementProject showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            {/* <Route exact path='/work-integrated-learning/intrusion-detection-project' element={<Navigate to="/careers/work-integrated-learning/intrusion-detection-project" />}/> */}
            <Route exact path='/programs/work-integrated-learning/intrusion-detection-project' element={<IntrusionDetectionProject showRegisterForm={showRegisterForm} setShowRegisterForm={setShowRegisterForm} />}/>
            <Route exact path='/events' element={<Events />} />
            <Route exact path='/spotlight' element={<Navigate to='/insights/spotlight' />} />
            <Route exact path='/insights/spotlight' element={<StudentsSpotlight showUserInterestForm={showUserInterestForm} setShowUserInterestForm={setShowUserInterestForm} download={SpotlightPackage} downloadFileName={'STEM_STUDENT_SPOTLIGHT_PROGRAM_PACKAGE'} />}/>
            <Route exact path='/research' element={<ResearchOne />}/>
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route exact path='/industry-engagement' element={<AIForm />} />
            <Route exact path='/programs/discover-ai' element={<DiscoverAI />}/>
            <Route exact path='/solutions/return-to-work-prediction' element={<ReturnToWorkPrediction />} />
            <Route exact path='/solutions/return-to-work-prediction-test' element={<ReturnToWorkPredictionTest />} />
            <Route exact path='/solutions/healthcare-insights' element={<HealthCareInsights />} />
            <Route exact path='/solutions/healthcare-chatbot' element={<HealthcareChatbot />} />
            <Route exact path='/solutions/jobseeker-test' element={<JobseekerTest />} />
            <Route exact path='/solutions/ai-resume' element={<Jobseeker />} />
            <Route exact path='/solutions/regulatory-compliance-dashboard' element={<RegulatoryDashboardPage />} />
            <Route exact path='/solutions/regulatory-compliance-dashboard2' element={<RegulatoryDashboardPage2 />} />
            <Route exact path='/about-us' element={<AboutUs />} />

            <Route path='*' element={<Error/>}/>
          </Routes>
        </ScrollToTop>
      </Router>

      <ToastifyContainer
        position="top-center"
        className="mt--80"
        hideProgressBar={true}
        autoClose={3000}
      />      
    </>
  );
}

export default App;
